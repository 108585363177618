import { X_VEHICLE_TYPE } from "../constants/api-constants";
import { getDeviceType } from "../utils/helpers/get-device-type";

export default (api) => {

    const deviceType = getDeviceType();

    const updateFinanceOpted = (token, orderId, reqObj) => {
        const {apiSource = ""} = reqObj;
        return api.put(`/test-drive/v2/${orderId}/finance-details/`, reqObj, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: apiSource || deviceType
            }
        });
    };

    const bookTestDriveSlot = (token, orderId, reqObj) => {
        const {apiSource = ""} = reqObj || {};
        return api.put(`/test-drive/v2/${orderId}`, reqObj, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: apiSource || deviceType
            }
        });
    };

    const cancelTestDriveSlot = (token, orderId, reqObj) => {
        const {apiSource = ""} = reqObj;
        return api.put(`/test-drive/v2/${orderId}/cancel`, reqObj, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: apiSource || deviceType
            }
        });
    };

    const updateTradeInInterest = (token, orderId, interestedInTradein) => {
        return api.put(`/test-drive/v2/tradein-interested/${orderId}`, { interestedInTradein }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.car
            }
        });
    };

    const getTradeInInterest = (token, orderId) => {
        return api.get(`/test-drive/v2/tradein-interested/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.car
            }
        });
    };

    return {
        updateFinanceOpted,
        bookTestDriveSlot,
        cancelTestDriveSlot,
        updateTradeInInterest,
        getTradeInInterest
    };
};
