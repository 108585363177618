
const getDecodedWebViewUrlParameter = (search) => {
    try {
        const params = new window.URLSearchParams(search);
        const result = {};

        for (const [key, value] of params.entries()) {
            try {
                result[key] = JSON.parse(decodeURIComponent(value));
            } catch (e) {
                result[key] = decodeURIComponent(value);
            }
        }

        return result;
    } catch (error) {
        return {};
    }
};

export { getDecodedWebViewUrlParameter };
