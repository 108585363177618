import { useCallback } from "react";
import { WEB_VIEW_EXPERIMENT_TO_REDUCER_MAPPING } from "../utils/statsig-ab-utils/constant";
import { useDispatch } from "react-redux";
import { setExperiment as setExperimentConnect } from "../components/shared/ab-experiment/actions";
import { getDecodedWebViewUrlParameter } from "../utils/helpers/get-web-view-experiments";

const useWebViewExperiments = (search) => {
    const dispatch = useDispatch();

    const resetExperimentsWithViewExperiments = useCallback(() => {
        const { data: { experiments: webViewExperiments = {} } = {} } = getDecodedWebViewUrlParameter(search) || {};

        if (webViewExperiments && Object.keys(webViewExperiments).length > 0) {
            Object.entries(webViewExperiments).forEach(([key, value]) => {
                const reducerKey = WEB_VIEW_EXPERIMENT_TO_REDUCER_MAPPING[key];
                if (reducerKey) {
                    dispatch(setExperimentConnect(reducerKey, value));
                }
            });
        }
    }, [dispatch, search]);

    return { resetExperimentsWithViewExperiments };
};

export default useWebViewExperiments;
