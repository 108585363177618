/* eslint-disable max-statements */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { captureException } from "@sentry/browser";
import Modal from "../../../../components/shared/modal";
import InputText from "../../../../components/shared/input-text";
import Button from "../../../../components/shared/button";
import CloseIcon from "./images/close.svg";
import InfoIcon from "./images/info.svg";
import StepOne from "./images/step-1.png";
import StepTwo from "./images/step-2.png";
import StepThree from "./images/step-3.png";
import StepFour from "./images/step-4.png";
import { validateEmail } from "../../../../utils/validators/email-validator";
import { NUMBER } from "../../../constants/app-constants";
import InputMobile from "../../../../components/shared/input-mobile";
import getCurrentPage from "../../../../utils/helpers/get-current-page";
import styles from "./styles.css";

// Tracking
import { desktopCleverTapEvent } from "../../../../tracking";
import { trackC2bMobileCustomEvents } from "../../../tracking";
import { C2B_AU_MOBILE_EVENTS } from "../../../tracking/c2b-mobile-events";
import { cleverTapLogin } from "../../../../tracking";
import { CLEVERTAP_EVENT_MAP } from "../../../../tracking/au.desktop-clevertap";
import {  formatMobileNumber, mobileNumberValidatorC2B } from "../../../utils/helpers/mobile-number-validator";

const MOBILE_REGEX_AU = new RegExp(/^\d{8}$/);

const EnterGuestEmailModal = ({
    email,
    isVisible,
    getGuestTokenConnect,
    setProfileDetailsConnect,
    setLoginSuccessResponseConnect,
    clearCallbacksAndClose,
    onGuestEmailEnteredCallback,
    handleBack,
    gaId,
    isMobileMandatory,
    updateProfileDetailsConnect,
    isLoggedIn,
    setUserEmailViaGuestLoginConnect,
    setUserMobileViaGuestLoginConnect,
    mobile
}) => {
    const [userEmail, setUserEmail ] = useState(email);
    const [userMobile, setUserMobile] = useState(() => formatMobileNumber(mobile) || null);
    const disableProceedCta =
    isMobileMandatory
        ? !validateEmail(userEmail) || !mobileNumberValidatorC2B(userMobile)
        : !validateEmail(userEmail);

    useEffect(() => {
        const variant = isMobileMandatory ? "experiment" : "control";
        trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.EMAIL_POPUP_OPENED, {
            eventLabel: `variant_${variant}_${gaId}`
        });
        setUserEmail(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    // eslint-disable-next-line complexity
    const handleClick = async () => {
        const date = new Date();
        const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const day = weekday[date.getDay()];
        let profileResponse = {};
        try {
            const variant = isMobileMandatory ? "experiment" : "control";
            trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.EMAIL_ID_SCREEN, {
                eventLabel: "proceed to valuation clicked"
            });
            trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.EMAIL_POPUP_PROCEED_CLICKED, {
                eventLabel: `variant_${variant}_${gaId}_${userMobile}`
            });
            /**
             * if isLoggedIn (from redux store) is true, then user is already logged in
             * dont call getGuestTokenConnect, directly call setProfileDetailsConnect
             * and update the profile
             * Update the info in redux only if the user is not logged in
             * if the user is already logged in, then the redux store will not be updated by the email and mobile entered in the gues login modal
             */
            if (isLoggedIn) {
                if (!mobile) {
                    if (isMobileMandatory) {
                        await updateProfileDetailsConnect({
                            phone: `4${userMobile}`
                        });
                    }
                    await setProfileDetailsConnect({
                        email: userEmail,
                        ...(userMobile && userMobile.length === NUMBER.EIGHT && MOBILE_REGEX_AU.test(userMobile) && { mobile: `4${userMobile}`})
                    });
                }
                setUserEmailViaGuestLoginConnect(userEmail);
                setUserMobileViaGuestLoginConnect(userMobile);
            } else {
                profileResponse =  await getGuestTokenConnect({username: userEmail, mobile: `4${userMobile}`, addressType: "EMAIL", token: ""});
                if (profileResponse.isNewUser) {
                    cleverTapLogin({email: userEmail, mobile: userMobile || ""});
                }
                if (isMobileMandatory) {
                    await updateProfileDetailsConnect({
                        phone: `4${userMobile}`
                    });
                }
                await setProfileDetailsConnect({
                    ...profileResponse,
                    email: userEmail,
                    secureToken: profileResponse.access_token,
                    ...(userMobile && userMobile.length === NUMBER.EIGHT && MOBILE_REGEX_AU.test(userMobile) && { mobile: `4${userMobile}`})
                });
                setUserEmailViaGuestLoginConnect(userEmail);
                setUserMobileViaGuestLoginConnect(userMobile);
                setLoginSuccessResponseConnect(profileResponse);
            }
            onGuestEmailEnteredCallback({
                userEmail,
                ...profileResponse,
                ...(userMobile && userMobile.length === NUMBER.EIGHT && MOBILE_REGEX_AU.test(userMobile) && { mobileNo: userMobile})
            });
            clearCallbacksAndClose();
            const c2bData = {...(userMobile && userMobile.length === NUMBER.EIGHT && MOBILE_REGEX_AU.test(userMobile) && {"Phone Number": `+614${userMobile}`}), "User ID": userEmail, "C2B Day": day};
            const c2bUserProperty = {"Site": {
                ...c2bData
            }};
            desktopCleverTapEvent(CLEVERTAP_EVENT_MAP.C2B_EMAIL_ENTERED, c2bData);
            window.clevertap.profile.push(c2bUserProperty);
            if (getCurrentPage().isC2BLandingPage) {
                trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_EMAIL_POPUP_PROCEED_CLICK, {
                    eventLabel: userEmail
                });
            }
        } catch (error) {
            captureException("guest_login_failed");
        }
    };

    const trackInputEvent = () => {
        trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.EMAIL_ID_SCREEN, {
            eventLabel: "email entered"
        });
    };

    const trackMobileEvent = () => {
        const variant = isMobileMandatory ? "experiment" : "control";
        trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.MOBILE_NUMBER_ENTERED, {
            eventLabel: `variant_${variant}_${gaId}`
        });
    };

    return (
        <Modal isOpen={isVisible}>
            <div styleName={"styles.loginModal"}>
                <div styleName={"styles.leftPanel"}>
                    <span styleName={"styles.close"}>
                        <img src={CloseIcon} onClick={handleBack} type="grey" alt="Login modal close icon" />
                    </span>
                    <div styleName={"styles.leftPanelTopWrapper"}>
                        <p styleName={"styles.leftHeading"}>Enter your details to proceed</p>
                        <div styleName={"styles.inputWrapper"}>
                            <InputText
                                id="userEmail"
                                placeholder={"Enter email address*"}
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                onBlurCallback={trackInputEvent}
                                validator={validateEmail}
                                errorMessage={"Please enter a valid email address"}
                            />
                        </div>
                        <div styleName={"styles.inputContainer"}>
                            <InputMobile
                                name="userMobile"
                                value={userMobile}
                                onChange={(e) => setUserMobile(e.target.value)}
                                countryCode="61 4"
                                showErrorOnFocus = {true}
                                validator= {(value) => (MOBILE_REGEX_AU.test(value))}
                                errorMessage={"Please enter a valid mobile number"}
                                maxLength={8}
                                onBlurCallback={trackMobileEvent}
                                placeholder={`Enter mobile number${isMobileMandatory ? "*" : ""}`}
                                isPadding
                            />
                        </div>
                        <p styleName={"styles.bottomText"}><img src={InfoIcon} />Your details will be used to contact you for  information related to your car.</p>
                        <Button disabled={disableProceedCta} onClick={handleClick} text="PROCEED" />
                        {/* <p styleName={"styles.subText"}>This will help us give you the best<br />experience on our platform</p> */}
                    </div>
                    <p styleName={"styles.disclaimerText"}>By providing your email ID and mobile number, you agree to CARS24's <a href="/terms-of-use/" target="_blank">Terms of Service</a> and <a href="/privacy-policy-statement/" target="_blank">Privacy Policy</a>, understand that an account will be created using your email, and consent to receive communications from CARS24 and its trusted partners.</p>
                </div>
                <div styleName={"styles.rightPanel"}>
                    <h3 styleName={"styles.heading"}>
                            How CARS24 works
                    </h3>
                    <ul styleName={"styles.benefitsList"}>
                        <li>
                            <span styleName={"styles.icon"}>
                                <img src={StepOne} alt="7 Day Returns" />
                            </span>
                            Instant valuation
                        </li>
                        <li>
                            <span styleName={"styles.icon"}>
                                <img src={StepTwo} alt="7 Day Returns" />
                            </span>
                            We pick it up from<br />your home
                        </li>
                        <li>
                            <span styleName={"styles.icon"}>
                                <img src={StepThree} alt="7 Day Returns" />
                            </span>
                            Valuation valid<br />for 7 days
                        </li>
                        <li>
                            <span styleName={"styles.icon"}>
                                <img src={StepFour} alt="7 Day Returns" />
                            </span>
                            Instant secure<br />payments
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

EnterGuestEmailModal.propTypes = {
    isVisible: PropTypes.bool,
    getGuestTokenConnect: PropTypes.func,
    email: PropTypes.string,
    setProfileDetailsConnect: PropTypes.func,
    setLoginSuccessResponseConnect: PropTypes.func,
    clearCallbacksAndClose: PropTypes.func,
    getTradeInDetailsConnect: PropTypes.func,
    onGuestEmailEnteredCallback: PropTypes.func,
    handleBack: PropTypes.func,
    gaId: PropTypes.string,
    isMobileMandatory: PropTypes.bool,
    isOnlyPhoneNumberCollect: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    updateProfileDetailsConnect: PropTypes.func,
    mobile: PropTypes.string,
    setUserEmailViaGuestLoginConnect: PropTypes.func,
    setUserMobileViaGuestLoginConnect: PropTypes.func
};

export default EnterGuestEmailModal;
